import AWS from "aws-sdk";
import { config } from "../config";
// Initialize the Amazon Cognito credentials provider.
AWS.config.region = "eu-west-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: config.envIdentityPoolId
});
const backendModule = {
  listLessons: callBack => {
    /// Prepare to call Lambda function
    const lambda = createLambda();
    var params = assembleParams("listLessons", "{}");

    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);
      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  },
  getAllGroupsOfSpecificType: (type, callBack) => {
    const lambda = createLambda();
    var params = assembleParams(
      "getAllGroupsOfSpecificType",
      JSON.stringify({ type: type })
    );
    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);
      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  },
  getAllGroupsWithRequirementsAndSpecificType: (
    requirements,
    wantedType,
    callBack
  ) => {
    const lambda = createLambda();

    var params = assembleParams(
      "getAllGroupsWithRequirementsAndSpecificType",
      JSON.stringify({ requirements: requirements, wantedType: wantedType })
    );
    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);
      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  },
  getProgress: (uuid, callBack) => {
    const lambda = createLambda();
    var params = assembleParams(
      "getLessonProgress",
      JSON.stringify({ uuid: uuid })
    );
    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);
      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  },
  setProgress: (uuid, meta, callBack) => {
    const lambda = createLambda();

    var params = assembleParams(
      "setLessonProgress",
      JSON.stringify({ uuid: uuid, meta: meta })
    );
    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);
      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  },
  deleteProgress: (uuid, callBack) => {
    const lambda = createLambda();
    var params = assembleParams(
      "deleteLessonProgress",
      JSON.stringify({ uuid: uuid })
    );

    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);
      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  },
  loadImage: (fileName, callBack) => {
    const lambda = createLambda();
    var params = assembleParams(
      "getImage",
      JSON.stringify({ fileName: fileName })
    );
    lambda.invoke(params, function(error, data) {
      const results = JSON.parse(data.Payload);

      if (error || results.isError) {
        callBack(true, { ...error, ...results.data });
      } else {
        callBack(false, results.data);
      }
    });
  }
};
/**
 * Helper function to create a new instance of Lambda.
 */
function createLambda() {
  return new AWS.Lambda({
    region: "eu-west-1",
    apiVersion: "2015-03-31"
  });
}

/**
 * Helper function that adds the given function name and payload to the standard lambda
 * parameter object, and returns this object.
 *
 * @param {string} functionName The name of the lambda function that is to be called.
 * @param {string} payload The stringified payload that is to be added to the lambda call.
 */
function assembleParams(functionName, payload) {
  return {
    FunctionName: functionName,
    InvocationType: "RequestResponse",
    LogType: "None",
    Payload: payload
  };
}
export default backendModule;
