import { IIdentifiable } from "../IIdentifiable";
import { ITimestamped } from "../ITimestamped";
import { SchoolClass } from "../schoolClass/SchoolClass"
import { Role } from "../role/Role";

export class MvLoginUser implements IIdentifiable, ITimestamped {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    username: string;
    password: string;
    givenName: string;
    surname: string;
    customerUuid: string;
    generatePassword: boolean;
    klass: SchoolClass = new SchoolClass();
    roles: { [name: string]: Role }[] = [];
    roleUuids: string[] = []; // used on insert for binding roles uuids
}