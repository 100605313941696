import { IIdentifiable } from "../IIdentifiable";
import { ITimestamped } from "../ITimestamped";

export class Customer implements IIdentifiable, ITimestamped {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    idpUuid: string;
    contractUuid: string;
    no_: string;
    name: string;
    address: string;
    city: string;
    postCode: string;
    phone: string;
    countryRegionCode: string;
    schoolCode: string;
    googleHd: string;
    googleOU: string;
}