import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Components
import SchoolYearRangePicker from "./SchoolYearRangePicker";
import PlatformPicker from "../components/PlatformPicker";
import Loader from "./Loader";
// Services
import lessonBackend from "../service/lessonBackendModule";

const LessonSetPicker = ({ disableAll, onStart }) => {
  const { t } = useTranslation();
  const [availableSchoolYearRanges, setAvailableSchoolYearRanges] = useState(
    []
  );
  const [
    allPotentialSchoolYearRanges,
    setAllPotentialSchoolYearRanges
  ] = useState([]);
  const [allPlatforms, setAllPlatforms] = useState([]);
  const [chosenPlatform, setChosenPlatform] = useState("");
  const [chosenSchoolYearRange, setChosenSchoolYearRange] = useState("");
  const [loaderVisibility, setLoaderVisibility] = useState(false);

  useState(() => {
    setLoaderVisibility(true);
    lessonBackend.getAllGroupsWithRequirementsAndSpecificType(
      [{ group: "targetGroup", value: "Grundskole" }],
      "schoolYearRange",
      (isError, input) => {
        setLoaderVisibility(false);
        if (isError) {
          setAllPotentialSchoolYearRanges([]);
        } else {
          setAllPotentialSchoolYearRanges(input);
        }
      }
    );
  }, []);
  /*
  Every time the user updates their choice of platform, we need to check which
  school year ranges are available.
  */
  useEffect(() => {
    if (chosenPlatform === "") {
      setAvailableSchoolYearRanges(allPotentialSchoolYearRanges);
    } else {
      setLoaderVisibility(true);
      lessonBackend.getAllGroupsWithRequirementsAndSpecificType(
        [
          { group: "targetGroup", value: "Grundskole" },
          { group: "platform", value: chosenPlatform }
        ],
        "schoolYearRange",
        (isError, input) => {
          setLoaderVisibility(false);
          if (!isError) {
            setAvailableSchoolYearRanges(input);
          } else {
            setAvailableSchoolYearRanges([]);
          }
        }
      );
    }
  }, [chosenPlatform, allPotentialSchoolYearRanges]);

  /**
   * Every time, the the user picks a new school year range, we need to update the
   * available platforms.
   */
  useEffect(() => {
    // If user hasn't chosen a school year range yet.
    if (chosenSchoolYearRange === "") {
      setLoaderVisibility(true);
      lessonBackend.getAllGroupsWithRequirementsAndSpecificType(
        [{ group: "targetGroup", value: "Grundskole" }],
        "platform",
        (isError, input) => {
          setLoaderVisibility(false);
          if (!isError) {
            setAllPlatforms(input);
          } else {
            setAllPlatforms([]);
          }
        }
      );
    } else {
      setLoaderVisibility(true);
      lessonBackend.getAllGroupsWithRequirementsAndSpecificType(
        [
          { group: "targetGroup", value: "Grundskole" },
          { group: "schoolYearRange", value: chosenSchoolYearRange }
        ],
        "platform",
        (isError, input) => {
          setLoaderVisibility(false);
          if (!isError) {
            setAllPlatforms(input);
          } else {
            setAllPlatforms([]);
          }
        }
      );
    }
  }, [chosenSchoolYearRange]);

  return (
    <div className="edu-level-picker">
      <Loader isVisible={loaderVisibility} />
      <p>{t("classDescription")}</p>
      <SchoolYearRangePicker
        allPotentialSchoolYearRanges={allPotentialSchoolYearRanges}
        availableSchoolYearRanges={availableSchoolYearRanges}
        disableAll={disableAll}
        onNewSchoolYearRangeChosen={newSchoolYearRange => {
          setChosenSchoolYearRange(newSchoolYearRange);
        }}
      />

      <p>{t("platformDescription") + ":"}</p>
      <PlatformPicker
        platforms={allPlatforms}
        disableAll={disableAll}
        onNewPlatformChosen={newPlatform => {
          setChosenPlatform(newPlatform);
        }}
      />
      <button
        className="edu-start-button"
        onClick={() => {
          onStart(chosenPlatform, chosenSchoolYearRange);
        }}
        disabled={chosenPlatform === "" || chosenSchoolYearRange === ""}
      >
        {t("start")}
      </button>
    </div>
  );
};
export default LessonSetPicker;
