import React, { useState } from "react";

const SchoolYearRangePicker = ({
  allPotentialSchoolYearRanges,
  availableSchoolYearRanges,
  disableAll,
  onNewSchoolYearRangeChosen
}) => {
  const [chosenSchoolYearRange, setChosenSchoolYearRange] = useState("");

  return (
    <div className="school-year-range-choices">
      {allPotentialSchoolYearRanges.length
        ? allPotentialSchoolYearRanges.map((schoolYearRange, index) => {
            return (
              <button
                key={schoolYearRange + index.toString()}
                disabled={
                  disableAll ||
                  availableSchoolYearRanges.indexOf(schoolYearRange) === -1
                }
                className={
                  chosenSchoolYearRange === schoolYearRange
                    ? "edu-currently-chosen"
                    : "not-chosen"
                }
                onClick={() => {
                  if (chosenSchoolYearRange === schoolYearRange) {
                    setChosenSchoolYearRange("");
                    onNewSchoolYearRangeChosen("");
                  } else {
                    setChosenSchoolYearRange(schoolYearRange);
                    onNewSchoolYearRangeChosen(schoolYearRange);
                  }
                }}
              >
                {schoolYearRange}
              </button>
            );
          })
        : null}
    </div>
  );
};
export default SchoolYearRangePicker;
