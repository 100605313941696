import { JsonClient } from "../JsonClient";
import { SchoolClass } from "./SchoolClass";
import { ApiResponse, responseBuilder } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class SchoolClassService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}
    
    public getClass(schoolClassUuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/classes/${schoolClassUuid}`)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as SchoolClass;
                }

                return apiResponse;
            })
        ;
    }

    public getClasses(customerUuid: string, startIdx = 1, endIdx = 2): Promise<ApiResponse> {
        return this.client
            .get(`/classes/customer/${customerUuid}`, startIdx, endIdx)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as SchoolClass[];
                }

                return apiResponse;
            })
        ;
    }

    public getClassByName(customerUuid: string, name: string): Promise<ApiResponse> {
        let b64name = btoa(name);
        return this.client
            .get(`/classes/customer/${customerUuid}/name/${b64name}`)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as SchoolClass;
                }

                return apiResponse;
            })
        ;
    }

    public newClass(schoolClass: SchoolClass): Promise<ApiResponse> {
        return this.client
            .post('/classes', JSON.stringify(schoolClass))
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as SchoolClass;
                }

                return apiResponse;
            })
        ;
    }

    public updateClass(uuid: string, schoolClass: SchoolClass): Promise<ApiResponse> {
        return this.client
            .put(`/classes/${uuid}`, JSON.stringify(schoolClass))
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as SchoolClass;
                }

                return apiResponse;
            })
        ;
    }

    public deleteClass(uuid: string): Promise<ApiResponse> {
        return this.client
            .delete(`/classes/${uuid}`)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as SchoolClass;
                }

                return apiResponse;
            })
        ;
    }
}
