import React, { useState } from "react";
// Components
import PlatformPickerButton from "../components/PlatformPickerButton";
// Logos
import IntoWordsLogo from "../img/IntoWords.png";
import CDORDLogo from "../img/CD-Ord.png";

const PlatformPicker = ({ platforms, disableAll, onNewPlatformChosen }) => {
  const possibleCDORD = ["windows", "mac"];
  const possibleIntoWords = ["extension", "android", "ios"];

  const [chosenPlatform, setChosenPlatform] = useState("");
  return (
    <div className="platform-choices">
      <p>
        <img src={CDORDLogo} alt="CD-ORD" />
        CD-ORD
      </p>
      <div className="platform-group">
        {possibleCDORD.map((platform, index) => {
          return (
            <PlatformPickerButton
              key={platform + index.toString}
              platform={platform}
              allPlatforms={platforms}
              isDisabled={
                platforms.indexOf(platform.toLowerCase()) === -1 || disableAll
              }
              className={
                chosenPlatform.toLowerCase() === platform.toLowerCase()
                  ? "edu-platform-button edu-currently-chosen"
                  : "edu-platform-button"
              }
              onClick={() => {
                if (chosenPlatform === platform) {
                  setChosenPlatform("");
                  onNewPlatformChosen("");
                } else {
                  setChosenPlatform(platform);
                  onNewPlatformChosen(platform);
                }
              }}
            />
          );
        })}
      </div>

      <p>
        <img src={IntoWordsLogo} alt="IntoWords" />
        IntoWords
      </p>
      <div className="platform-group">
        {possibleIntoWords.map((platform, index) => {
          return (
            <PlatformPickerButton
              key={platform + index.toString()}
              platform={platform}
              allPlatforms={platforms}
              isDisabled={
                platforms.indexOf(platform.toLowerCase()) === -1 || disableAll
              }
              className={
                chosenPlatform === platform
                  ? "edu-platform-button edu-currently-chosen"
                  : "edu-platform-button"
              }
              onClick={() => {
                setChosenPlatform(platform);
                onNewPlatformChosen(platform);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default PlatformPicker;
