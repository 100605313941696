import { HmacSHA256, Hash, enc } from "crypto-js/index";

export class Hmac256Hasher {
    constructor(
        protected hmacSha256 = HmacSHA256,
    ) {}

    /**
     * hash returns a base64 encoded string
     */
    public hash(body: string, verb: string, url: string, timestamp: string, secret: string) {
        let hash = this.hmacSha256(body + verb + url + timestamp, secret);
        return enc.Base64.stringify(hash);
    }
}