
import { IIdentifiable } from "../IIdentifiable";
import { ITimestamped } from "../ITimestamped";

export class Role implements IIdentifiable, ITimestamped {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    type: string;
    description: string;
    name: string;
    bitmask: number;
    customerUuid: string;
}

