import { JsonClient } from "../JsonClient";
import { Whoami } from "./Whoami";
import { responseBuilder, ApiResponse } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class SignonService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}
    
    public whoami(sessionId: string): Promise<ApiResponse> {
        return this.client
            .get('/users/whoami')
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Whoami;
                }

                return apiResponse;
            })
        ;
    }

    public keepAlive(): Promise<ApiResponse> {
        return this.client
            .get('/keepalive')
            .then( r =>  this.apiResponseFactory.from(r))
        ;
    }
    
    public getSecret(clientId: string): Promise<ApiResponse> {
        return this.client
            .get(`/users/GenerateTempSecret?serviceName=${clientId}`)
            .then( r =>  this.apiResponseFactory.from(r))
        ;
    }
}