import React, { useContext } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

import { LoginStateContext } from "../Context/LogInStateContext";
import { isDevelopment } from "../service/checkEnvironment";

import "../../CSS/HeaderCollapsibleStyle.css";
import English from "../img/England.svg";
import Danish from "../img/Denmark.svg";
import Swedish from "../img/Sweden.svg";
import Norwegian from "../img/Norway.svg";
import ForgotPassword from "./ForgotPassword";
import { setCookie } from "../service/cookieHandling";

/**
 * A component for displaying more detailed header options.
 *
 * @param {object} info All the info necessary for the collapsible, i.e.: state, navItems, onCloceCollapsible, onLogoutClicked.
 * @param {string} info.state A string for defining which info should be shown.
 * Currently the following are valid (all others will be counted as no collapsible wanted): MENU, LANGUAGE, PROFILE, PASSWORD.
 * @param {object[]} info.navItems An array containing objects of link and title for each nav item in the menu.
 * @param {string} info.navItems.link The link to a different page of the webshop.
 * @param {string} info.navItems.title The title that should be displayed for the corresponding link.
 * @param {function} info.onCloseCollapsible A function that is called when the collapsible needs to be closed again.
 * @param {function} info.onLogoutClicked A function that bubbles up the logout event.
 */
const HeaderCollapsible = ({
  state,
  navItems,
  onCloseCollapsible,
  onLogoutClicked
}) => {
  const { t, i18n } = useTranslation();

  const { isLoggedIn } = useContext(LoginStateContext);

  /**
   * Method to determine if a menu items is to be hiddden based on login state
   * @param {link} The link of the menu item
   */
  function hideMenuItem({ link }) {
    if (link === "userData") return !isLoggedIn;
  }

  let collapsible = null;

  switch (state) {
    case "MENU":
      collapsible = (
        <div className="header-collapsible main-navigation">
          {navItems.map((item, index) =>
            hideMenuItem({ link: item.link }) ? null : (
              <Link
                key={item.title + index}
                className="collapsible-button"
                to={item.link}
                onClick={() => {
                  onCloseCollapsible();
                }}
              >
                <img alt="" src={item.icon} />
                <span>{item.title}</span>
              </Link>
            )
          )}
        </div>
      );
      break;
    case "LANGUAGE":
      collapsible = (
        <div className="header-collapsible header-language-selection">
          {[
            { label: "NORSK", name: "Norwegian", img: Norwegian },
            { label: "DANSK", name: "Danish", img: Danish },
            { label: "ENGLISH", name: "English", img: English },
            { label: "SVENSKA", name: "Swedish", img: Swedish }
          ].map((language, index) => (
            <button
              key={language.name + index.toString()}
              className="collapsible-button"
              onClick={() => {
                i18n.changeLanguage(language.name);
                setCookie({
                  name: "webshopLanguage",
                  value: language.name,
                  options: "path=/;secure"
                });
                onCloseCollapsible();
              }}
            >
              <img src={language.img} alt={language.label} />{" "}
              <span>{language.label}</span>
            </button>
          ))}
        </div>
      );
      break;
    case "PROFILE":
      collapsible = (
        <div className="header-collapsible collapsible-logout">
          <button
            onClick={() => {
              onLogoutClicked();
            }}
          >
            {t("logOut")}
          </button>
        </div>
      );
      break;
    case "PASSWORD":
      collapsible = (
        <div className="header-collapsible">
          <ForgotPassword />
        </div>
      );
      break;
    default:
      collapsible = null;
  }
  return collapsible;
};
export default HeaderCollapsible;
