import React, { useState /* useContext */ } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
// Custom components
import HeaderCollapsible from "./HeaderCollapsible";
// CSS
import "../../CSS/headerStyle.css";
// Images
import logo from "../img/logo.svg";
import English from "../img/England.svg";
import Danish from "../img/Denmark.svg";
import Swedish from "../img/Sweden.svg";
import Norwegian from "../img/Norway.svg";
/* import profile from "../img/profile.svg";
import Menu from "../img/menu.svg";
import Password from "../img/questionmark.svg"; */

/* import { LoginStateContext } from "../Context/LogInStateContext";
import { UserContext } from "../Context/UserContext"; */

import { config } from "../config";
import {
  isSessionValid,
  getCookieSessionId,
  deleteSessionCookie
} from "../service/authStorage";

/**
 * Component that takes care of the entire header, including a top menu, logout functionality, and language options.
 *
 * @param {object} headerInformation - All the information necessary for the header.
 * @param {object[]} headerInformation.menuItems The items that the menu should link to.
 */
const Header = ({ menuItems }) => {
  const { /* t,  */ i18n } = useTranslation();
  // The following states are valid, all other strings will be counted as "COLLAPSED": COLLAPSED, LANGUAGE, MENU, PROFILE, PASSWORD.
  const [settingsCollapsibleState, updateSettingsCollapsibleState] = useState(
    "COLLAPSED"
  );

  /*   const { isLoggedIn } = useContext(LoginStateContext);

  const { userData } = useContext(UserContext); */

  /**
   *
   * Translates a given language into a div with an image and the native name of the language.
   * Currently, Danish, Norwegian (Bokmal), Swedish, and English can be matched.
   * All other input is counted as English.
   *
   * @param {object} info An object containing a language name.
   * @param {string} info.language The language (in English) that is to be localized.
   *
   * @returns A div containing an image of the appropriate flag as well as a span with the localized language.
   *
   */
  function localizeLanguage({ language }) {
    let localizedLanguage = null;
    switch (language) {
      case "Norwegian":
        localizedLanguage = (
          <div className="header-language-area">
            <img className="header-img" src={Norwegian} alt="" />
            <span>NORSK</span>
          </div>
        );
        break;
      case "Swedish":
        localizedLanguage = (
          <div className="header-language-area">
            <img className="header-img" src={Swedish} alt="" />
            <span>SVENSKA</span>
          </div>
        );
        break;
      case "Danish":
        localizedLanguage = (
          <div className="header-language-area">
            <img className="header-img" src={Danish} alt="" />
            <span>DANSK</span>
          </div>
        );
        break;
      default:
        localizedLanguage = (
          <div className="header-language-area">
            <img className="header-img" src={English} alt="" />
            <span>ENGLISH</span>
          </div>
        );
        break;
    }
    return localizedLanguage;
  }

  function handleLogout() {
    updateSettingsCollapsibleState("COLLAPSED");
    var sessionId = null;
    if (isSessionValid()) {
      sessionId = getCookieSessionId();
      deleteSessionCookie();
      window.location = config.logout.url.replace("{SessionID}", sessionId);
    }
  }

  return (
    <header className="entire-header">
      <div className="logo-and-settings">
        <Link className="header-logo-link" to="/">
          <img
            className="header-logo-img"
            src={logo}
            alt="Vitec company logo"
          />
        </Link>
        <div className="header-settings">
          <div className="settings-only">
            {/* <button
              className="header-settings-button link"
              onClick={() => {
                isLoggedIn
                  ? settingsCollapsibleState === "PROFILE"
                    ? updateSettingsCollapsibleState("COLLAPSED")
                    : updateSettingsCollapsibleState("PROFILE")
                  : (window.location = config.login.url);
              }}
            >
              <img className="header-img" src={profile} alt="" />

              <span>
                {isLoggedIn
                  ? userData.email || userData.givenName
                  : t("loginText")}
              </span>
            </button> */}
            {/* {!isLoggedIn ? (
              <button
                className="header-settings-button link"
                onClick={() => {
                  settingsCollapsibleState === "PASSWORD"
                    ? updateSettingsCollapsibleState("COLLAPSED")
                    : updateSettingsCollapsibleState("PASSWORD");
                }}
              >
                <img className="header-img" src={Password} alt="" />
                <span>{t("forgotPassword")}</span>
              </button>
            ) : null} */}
            <button
              className="header-settings-button link"
              onClick={() => {
                settingsCollapsibleState === "LANGUAGE"
                  ? updateSettingsCollapsibleState("COLLAPSED")
                  : updateSettingsCollapsibleState("LANGUAGE");
              }}
            >
              {localizeLanguage({ language: i18n.language })}
            </button>
            {/* <button
              className=" header-settings-button link"
              onClick={() => {
                settingsCollapsibleState === "MENU"
                  ? updateSettingsCollapsibleState("COLLAPSED")
                  : updateSettingsCollapsibleState("MENU");
              }}
            >
              <img className="header-img" src={Menu} alt="" />
              <span>{t("menuText").toUpperCase()}</span>
            </button> */}
          </div>
        </div>
      </div>

      <HeaderCollapsible
        state={settingsCollapsibleState}
        navItems={menuItems}
        onCloseCollapsible={() => updateSettingsCollapsibleState("COLLAPSED")}
        onLogoutClicked={() => {
          handleLogout();
        }}
      />
    </header>
  );
};
export default Header;
