export const TEST_CONFIG = {
  envIdentityPoolId: 'eu-west-1:cbf0b709-083c-486f-88e4-339aefa0a590',
  api: {
    clientId: "Privatsalg",
    signon: {
      url: "https://mvidsignonapi-test.vitec-mv.com"
    },
    mvidapi: {
      url: "https://mvidapi-test.vitec-mv.com"
    }
  },
  session: {
    lifetime: 60 * 30, // sec
    idKey: "sessionId",
    lastUpdateKey: "sessionLastUpdate",
    keepAlive: 1000 * 60 * 15 // mili
  },
  login: {
    url: `https://signon-test.vitec-mv.com/index.php?returnUrl=${window.location.protocol}//${window.location.host}`
  },
  logout: {
    url: `https://signon-test.vitec-mv.com/logout.php?SessionID={SessionID}&return_to=${window.location.protocol}//${window.location.host}`
  },
  stripe: {
    apiKey:
      "pk_test_51HFI7CJnVGYo9fh6M8gnoD0cem9EiUwGTEr9x1qjtaSdD865SG7cmJwLEGld8e9d3oUKQ3Xg2x3cs411Nfidr9Pe00r8dWHqZO"
  }
};
