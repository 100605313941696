export class Whoami {
    Uid: string;
    UserName: string;
    SurName: string;
    GivenName: string;
    SchoolCodes: { [index: string]: string};
    CustomrtKey: { [index: string]: string};
    SchoolTypes: { [index: string]: number};
    GoogleHD: string;
    GoogleOU: string;
    Customers: { [index: string]: string};
    roles: {};
}
