import { IIdentifiable } from "../IIdentifiable";
import { ITimestamped } from "../ITimestamped";

export class Permission implements IIdentifiable, ITimestamped {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    name: string;
    bit: number;
    customerUuid: string;
}