import { JsonClient } from "../JsonClient";
import { Role } from "./Role";
import { ApiResponse, responseBuilder } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class RoleService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}

    public getRoles() {
        return this.client
            .get('/roles')
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Role[];
                }

                return apiResponse;
            })
        ;
    }

    public getRolesByName(customerUuid: string, name: string): Promise<ApiResponse> {
        let b64name = btoa(name);

        return this.client
            .get(`/roles/customer/${customerUuid}/name/${b64name}`)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Role[];
                }

                return apiResponse;
            })
        ;
    }
}