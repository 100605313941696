import React, { useState } from "react";
import "../../CSS/ForgotPasswordStyle.css";

import { useTranslation } from "react-i18next";
import emailLogo from "../img/email.svg";

import { serviceConfigurator } from "../service/ServiceConfigurator";
import { subscriptionService } from "../api/service";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isMessageVisible, updateMessageVisibility] = useState(false);
  const [getEmail, updateEmail] = useState("");
  const [isDisabled, updateIsDisabled] = useState(false);

  /**
   * Starting the reset password flow by submitting the entered e-mail address to the backend, and showing a confirmation message to the user.
   * Should the e-mail address not be registered in the backend, the backend simply won't send the e-mail, but the message is still shown.
   */
  function handleSubmit() {
    serviceConfigurator();
    subscriptionService
      .get()
      .createPasswordResetRequest(getEmail)
      .then(() => {
        updateMessageVisibility(true);
      });
  }

  return (
    <div className="forgot-password-intro">
      <form
        className="forgot-password-form"
        onSubmit={e => {
          e.preventDefault();
          updateIsDisabled(true);
          handleSubmit();
        }}
      >
        <p>{t("forgotPasswordIntroduction")}</p>
        <label className="forgot-password-box">
          <p className="forgot-password-box-info">
            <img src={emailLogo} alt="" /> {t("eMail")}
          </p>
          <input
            type="email"
            onChange={e => updateEmail(e.target.value)}
            required
            onFocus={() => {
              updateIsDisabled(false);
              updateMessageVisibility(false);
            }}
          />
        </label>
        <input
          type="submit"
          className="forgot-password-submit-button"
          value={t("applyForNewPasswordButtonText")}
          disabled={isDisabled}
        />
      </form>
      {isMessageVisible ? (
        <p className="forgot-password-confirmation">
          {t("forgotPasswordConfirmation")}
        </p>
      ) : null}
    </div>
  );
};

export default ForgotPassword;
