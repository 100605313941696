import { JsonClient } from "../JsonClient";
import { Subscription } from "./Subscription";
import { ApiResponse } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";
import { Invoice } from "./Invoice";

export class SubscriptionService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}

    public getSubscription(userUuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/subscriptions/${userUuid}`)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Subscription;
                }

                return apiResponse;
            })
        ;    
    }

    // public newSubscription(subscription: Subscription): Promise<ApiResponse> {
    //     return this.client
    //         .post(`/subscriptions`, JSON.stringify(subscription))
    //         .then (r => this.apiResponseFactory.from(r))
    //         .then ( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as Subscription;
    //             }

    //             return apiResponse;
    //         })
    //     ;
    // }

    // public createUser(subscription: Subscription): Promise<ApiResponse> {
    //     return this.client
    //         .post(`/subscriptions/createUser`, JSON.stringify(subscription))
    //         .then (r => this.apiResponseFactory.from(r))
    //         .then ( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as Subscription;
    //             }
    //             return apiResponse;
    //         })
    //     ;
    // }

    public addSubscriptionToUser(userUuid: string, subscription: Subscription): Promise<ApiResponse> {
        return this.client
            .post(`/subscriptions/addSubscription/${userUuid}`, JSON.stringify(subscription))
            .then ( r => this.apiResponseFactory.from(r))
            .then ( apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as string;
                }
                return apiResponse;
            })
        ;
    }

    // public deleteSubscription(userUuid: string): Promise<ApiResponse> {
    //     return this.client
    //         .delete(`/subscriptions/${userUuid}`)
    //         .then ( r => this.apiResponseFactory.from(r))
    //         .then ( apiResponse => {
    //             if (apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as Subscription;
    //             }

    //             return apiResponse;
    //         })
    //     ;
    // }

    public updateSubscription(userUuid: string, subscription: Subscription): Promise<ApiResponse> {
        return this.client
            .put(`/subscriptions/${userUuid}`, JSON.stringify(subscription))
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Subscription;
                }

                return apiResponse;
            })
        ;
    }

    // public updatePassword(userUuid: string, newPassword: string): Promise<ApiResponse> {
    //     return this.client
    //         .put(`/subscriptions/ChangePassword/${userUuid}/`, JSON.stringify(newPassword) )
    //         .then( r => this.apiResponseFactory.from(r))
    //         .then( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as boolean;
    //             }

    //             return apiResponse;
    //         })
    //     ;
    // }

    // public addProductToSubscription(userUuid: string, product: string): Promise<ApiResponse> {
    //     return this.client
    //         .put(`/subscriptions/AddProduct/${userUuid}`, JSON.stringify(product))
    //         .then( r => this.apiResponseFactory.from(r))
    //         .then ( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as Subscription;
    //             }

    //             return apiResponse;
    //         })
    //     ;
    // }

    // public removeProductToSubscription(userUuid: string, product: string): Promise<ApiResponse> {
    //     return this.client
    //         .delete(`/subscription/${userUuid}/RemoveProduct`)
    //         .then( r => this.apiResponseFactory.from(r))
    //         .then( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as Subscription;
    //             }

    //             return apiResponse;
    //         })
    //     ;
    // }

    // public renewSubscription(userUuid: string): Promise<ApiResponse> {
    //     return this.client
    //         .put(`/subscriptions/Renew/${userUuid}`)
    //         .then( r => this.apiResponseFactory.from(r))
    //         .then ( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as Subscription;
    //             }

    //             return apiResponse;
    //         })
    //     ;
    // }

    // public isUsernameUnique(usernameBase64: string): Promise<ApiResponse> {
    //     return this.client
    //         .get(`/subscriptions/IsUserNameUnique/${usernameBase64}`)
    //         .then( r => this.apiResponseFactory.from(r) )
    //         .then( apiResponse => {
    //             if (apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as boolean;
    //             }
                
    //             return apiResponse;
    //         })
    //     ;    
    // }

    // public startNewSubscription(subscription: Subscription): Promise<ApiResponse> {
    //     return this.client
    //         .post(`/subscriptions/startNewSubscription`, JSON.stringify(subscription))
    //         .then (r => this.apiResponseFactory.from(r))
    //         .then ( apiResponse => {
    //             if(apiResponse.ok) {
    //                 apiResponse.data = apiResponse.data as string;
    //             }
    //             return apiResponse;
    //         })
    //     ;
    // }
    
    public cancelSubscription(userUuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/subscriptions/Cancel/${userUuid}`)
            .then( r => this.apiResponseFactory.from(r) )
            .then( apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as boolean;
                }
                
                return apiResponse;
            })
        ;    
    }

    public updateCreditCard(userUuid: string): Promise<ApiResponse> {
        console.log("updateCreditCard called");
        return this.client
            .get(`/subscriptions/UpdateCreditCard/${userUuid}`)
            .then( r => this.apiResponseFactory.from(r) )
            .then( apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as boolean;
                }
                
                return apiResponse;
            })
        ;    
    }

    public getInvoices(userUuid: string): Promise<ApiResponse> {
        console.log("getInvoices called");
        return this.client
            .get(`/subscriptions/GetInvoices/${userUuid}`)
            .then( r => this.apiResponseFactory.from(r) )
            .then( apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Invoice[];
                }
                
                return apiResponse;
            })
        ;    
    }

    public createPasswordResetRequest(username: string): Promise<ApiResponse> {
        return this.client
            .get(`/subscriptions/createPasswordResetRequest/${username}`)
            .then( r => this.apiResponseFactory.from(r) )
            .then( apiResponse => {
                return apiResponse;
            })
    }

    public checkPasswordResetRequest(linkIdentifier: string): Promise<ApiResponse> {
        return this.client
            .get(`/subscriptions/CheckPasswordResetRequest/${linkIdentifier}`)
            .then ( r => this.apiResponseFactory.from(r)
            .then (apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as String;
                }

                return apiResponse;
            }))
    }

    public resetPassword(userUuid: string, newPassword: string): Promise<ApiResponse> {
        return this.client
            .post(`/subscriptions/ResetPassword/${userUuid}`, JSON.stringify(newPassword))
            .then( r => this.apiResponseFactory.from(r)
            .then (apiResponse => {
                return apiResponse;
            }))
    }
}
