import { Hmac256Hasher } from '../Hmac256Hasher';
import { JsonClient } from '../JsonClient';
import { SignonService } from './SignonService';
import { ApiResponseFactory } from '../ApiResponseFactory';

export class SignonServiceFactory {
    public make(baseURL: string, sessionId: string, clientId: string, secret: string) {
        const client = new JsonClient(baseURL, sessionId, clientId, secret, new Hmac256Hasher());
        const apiResponseFactory = new ApiResponseFactory();

        return new SignonService(client, apiResponseFactory);
    }
}
