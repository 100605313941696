
export class Subscription {
    email: string;
    password: string;
    givenName: string;
    surName: string;
    products: string[] = []
    address: string;
    city: string;
    postCode: string;
    phone: string;
    countryRegionCode: string;
    userUuid: string;
    IsActive: boolean;
    EndDate: Date;
    MarkedForDeletion: boolean;
    Consent: boolean;
    Newsletter: boolean;
}
