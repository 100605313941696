import { config } from "../config";
import { setCookie, getCookies } from "./cookieHandling";

function now() {
  return Math.floor(Date.now() / 1000);
}

export const getCookieSessionId = () => {
  let cookies = getCookies();
  return cookies[config.session.idKey];
};

export const refreshCookie = () => {
  setCookie({
    name: config.session.lastUpdateKey,
    value: now(),
    options: "path=/;secure"
  });
};

export const setCookieSession = sessionId => {
  setCookie({
    name: config.session.idKey,
    value: sessionId,
    options: "path=/;secure"
  });
  refreshCookie();
};

export const isSessionValid = () => {
  let cookies = getCookies();
  let lastUpdated = parseInt(cookies[config.session.lastUpdateKey], 10) || 0;

  return lastUpdated + config.session.lifetime > now();
};

export const purgeSessionCookie = () => {
  setCookie({
    name: config.session.idKey,
    value: "",
    options: "max-age=0;path=/;secure"
  });
  setCookie({
    name: config.session.lastUpdateKey,
    value: "",
    options: "max-age=0;path=/;secure"
  });
};

export const deleteSessionCookie = () => {
  setCookie({
    name: config.session.idKey,
    value: "",
    options: "expires=Thu, 01 Jan 1970 00:00:00 GMT;secure"
  });
  setCookie({
    name: config.session.lastUpdateKey,
    value: "",
    options: "expires=Thu, 01 Jan 1970 00:00:00 GMT;secure"
  });
};
