import { ApiResponse } from "./ApiResponse";

export class ApiResponseFactory {
    public from(r: Response): Promise<ApiResponse> {
        const response =  new ApiResponse();

        return r.text().then( text => {
            var json = null;
            let data = null;

            try {
                json = JSON.parse(text);
            } catch (parseError) {} // catch and release
            

            response.header = r.headers;
            response.status = r.status;
            response.ok = r.ok;

            if(json || typeof json === 'boolean') {
                data = json;
            } else {
                data = text;
            }

            // is status between inclusive 200-299 or a OK status but with error content
            let isOkResponseWithError = data && data.error && data.exceptionID;
            if(r.ok === false || isOkResponseWithError) {
                response.error = data.error ? data : {
                    error: data ? data : 'An error occurred'
                };
                response.ok = false;

                throw response;
            }
            response.data = data;

            return response;
        });
    }
}