import { 
    SignonServiceFactory,
    SubscriptionServiceFactory,
    UserManagementServiceFactory
    } from '@mvid/mvid-admin-library/dist';

let signonService = {
    configure: (baseURL, sessionId, clientId, secret) => {
        signonService.service = (new SignonServiceFactory()).make(baseURL, sessionId, clientId, secret);
    },
    get: () => {
        if(typeof signonService.service === "undefined") {
            return new Error("Service not ready");
        }
        return signonService.service;
    }
};

let subscriptionService = {
    configure: (baseURL, sessionId, clientId, secret) => {
        subscriptionService.service = (new SubscriptionServiceFactory()).make(baseURL, sessionId, clientId, secret);
    },
    get: () => {
        if(typeof subscriptionService.service === "undefined") {
            return new Error("Service not ready");
        }
        return subscriptionService.service;
    }
};

let userManagementService = {
    configure: (baseURL, sessionId, clientId, secret) => {
        userManagementService.service = (new UserManagementServiceFactory()).make(baseURL, sessionId, clientId, secret);
    },
    get: () => {
        if(typeof userManagementService.service === "undefined") {
            return new Error("Service not ready");
        }
        return userManagementService.service;
    }
};

export {
    signonService,
    subscriptionService,
    userManagementService
};