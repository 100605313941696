import { JsonClient } from "../JsonClient";
import { CreateUserRequest } from "./CreateUserRequest";
import { responseBuilder, ApiResponse } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class UserManagementService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}
    
    public createUserRequest(request: CreateUserRequest): Promise<ApiResponse> {
        return this.client
            .post(`/usermanagement/createUserRequest`, JSON.stringify(request))
            .then( r => this.apiResponseFactory.from(r)
            .then (apiResponse => {
                return apiResponse;
            }))
    }

    public verifyCreateUserRequest(identifier: string): Promise<ApiResponse> {
        return this.client
            .get(`/usermanagement/verifyUserCreateRequest/${identifier}`)
            .then( r => this.apiResponseFactory.from(r)
            .then (apiResponse => {
                return apiResponse;
            }))
    }
    
    public isUsernameUnique(usernameBase64: string): Promise<ApiResponse> {
        return this.client
            .get(`/usermanagement/isUsernameUnique/${usernameBase64}`)
            .then( r => this.apiResponseFactory.from(r) )
            .then( apiResponse => {
                if (apiResponse.ok) {
                    apiResponse.data = apiResponse.data as boolean;
                }                
                return apiResponse;
            });    
    }

}