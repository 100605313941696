import { IIdentifiable } from "../IIdentifiable";
import { ITimestamped } from "../ITimestamped";

export class SchoolClass implements IIdentifiable, ITimestamped {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    customerUuid: string;
    track: string;
    startYear: number;
    name: string;
}