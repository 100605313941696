import { JsonClient } from "../JsonClient";
import { CustomerService } from "./CustomerService";
import { Hmac256Hasher } from "../Hmac256Hasher";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class CustomerServiceFactory {
    public make(baseURL: string, sessionId: string, clientId: string, secret: string) {
        const client = new JsonClient(baseURL, sessionId, clientId, secret, new Hmac256Hasher);
        const apiResponseFactory = new ApiResponseFactory();
        
        return new CustomerService(client, apiResponseFactory);
    }
}
