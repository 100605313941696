// External
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, navigate } from "@reach/router";
import ReactPlayer from "react-player/vimeo";
import Helmet from "react-helmet";
// Stylesheet
import "../../CSS/EduPageStyle.css";
// Custom components
import Alert from "../components/AlertBox";
import LessonSetPicker from "../components/LessonSetPicker";
import Loader from "../components/Loader";
// Services
import backendModule from "../service/lessonBackendModule";
import { UserContext } from "../Context/UserContext";
import { LoginStateContext } from "../Context/LogInStateContext";
import { config } from "../config";

const EduPage = () => {
  const { t } = useTranslation();
  const [loaderState, setLoaderState] = useState(true);
  const { userData } = useContext(UserContext);
  const { isLoggedIn } = useContext(LoginStateContext);
  const [uuid, setUuid] = useState("");
  const [isNewUser, setIsNewUser] = useState();
  const originalMeta = {
    schoolYearRange: "",
    category: "A",
    lessonNumber: "1",
    lessonNumberSubGroup: "",
    platform: "",
    targetGroup: "Grundskole"
  };
  const [currentProgress, updateCurrentProgress] = useState({
    ...originalMeta
  });
  const [
    isDeletionCompleteAlertVisible,
    setDeletionCompleteAlertVisibility
  ] = useState(false);
  const [
    isDeleteProgressAlertVisible,
    setDeleteProgressAlertVisibility
  ] = useState(false);
  const [
    isCreateProgressAlertVisible,
    setCreateProgressAlertVisibility
  ] = useState(false);

  /*
  We need to retrieve the user's lesson progress, once the correct UUID was found.
  */
  useEffect(() => {
    setUuid(userData.userUuid);
    setLoaderState(true);
    backendModule.getProgress(userData.userUuid, (isError, input) => {
      setLoaderState(false);
      if (!isError) {
        if (input.platform === undefined) {
          setIsNewUser(true);
          updateCurrentProgress({ ...originalMeta });
        } else {
          setIsNewUser(false);
          updateCurrentProgress(input);
        }
      }
    });
  }, [userData]);

  return (
    <div className="edu-page">
      <Helmet>
        <title>{"Vitec MV - " + t("educationalMaterialPageTitle")}</title>
      </Helmet>
      {isDeletionCompleteAlertVisible ? (
        <Alert
          style="GOOD"
          title={t("progressDeletedTitle")}
          message={t("progressDeletedMessage")}
          defaultButtonAction={() => {
            setDeletionCompleteAlertVisibility(false);
            navigate("/");
          }}
        />
      ) : null}
      {isDeleteProgressAlertVisible ? (
        <Alert
          style="ERROR"
          title={t("confirmChoice")}
          message={t("deleteProgressConfirmationMessage")}
          defaultButtonTitle={t("cancel")}
          defaultButtonAction={() => {
            setDeleteProgressAlertVisibility(false);
          }}
          optionalButtonTitle={t("deleteProgressButtonTitle")}
          optionalButtonAction={() => {
            backendModule.deleteProgress(uuid, isError => {
              setDeleteProgressAlertVisibility(false);
              if (!isError) {
                setDeletionCompleteAlertVisibility(true);
              }
            });
          }}
        />
      ) : null}
      {isCreateProgressAlertVisible ? (
        <Alert
          style="NEUTRAL"
          title={t("errorTitle")}
          message={t("progressFailedMessage")}
          defaultButtonAction={() => {
            setCreateProgressAlertVisibility(false);
          }}
        />
      ) : null}
      <h1>{t("educationalMaterialPageTitle")}</h1>
      <Link className="edu-guide-link" to="guide-for-teachers">
        {t("teacherGuide")}
      </Link>
      <h2>{t("educationalMaterialIntroTitle")}</h2>
      <p>{t("educationalMaterialIntro")}</p>
      <ReactPlayer
        className="edu-intro-video"
        url={t("educationalMaterialIntroVideoLink")}
      />
      {isLoggedIn ? (
        <div className="edu-content">
          <h1>
            {isNewUser
              ? t("startEducationalMaterialTitle")
              : t("continueLessonsTitle")}
          </h1>

          <h2>{t("userGroupSchool")}</h2>

          <Loader isVisible={loaderState} />

          {// Don't show any values if we haven't found the correct values yet.
          loaderState === true || isNewUser === undefined ? null : isNewUser ? (
            <LessonSetPicker
              disableAll={false}
              onStart={(platform, schoolYearRange) => {
                setIsNewUser(false);
                updateCurrentProgress(oldValues => ({
                  ...oldValues,
                  platform: platform,
                  schoolYearRange: schoolYearRange
                }));
                setLoaderState(true);
                backendModule.setProgress(
                  uuid,
                  {
                    ...originalMeta,
                    schoolYearRange: schoolYearRange,
                    platform: platform
                  },
                  isError => {
                    setLoaderState(false);
                    if (!isError) {
                      navigate("undervisningsmateriale/lessons", {
                        state: {
                          meta: {
                            ...originalMeta,
                            targetGroup: "Grundskole",
                            schoolYearRange: schoolYearRange,
                            platform: platform
                          },
                          uuid: uuid
                        }
                      });
                    } else {
                      setCreateProgressAlertVisibility(true);
                    }
                  }
                );
              }}
            />
          ) : (
            <div className="edu-level-picker">
              <p>
                {t("chosenValuesDescription", {
                  schoolYearRange: currentProgress.schoolYearRange,
                  category: currentProgress.category,
                  lessonNumber: currentProgress.lessonNumber
                })}
              </p>
              <Link
                to={"lessons"}
                className="treat-as-button edu-start-button"
                state={{
                  meta: currentProgress,
                  uuid: uuid
                }}
              >
                {t("continue")}
              </Link>
              <button
                className="progress-delete-button"
                onClick={() => {
                  setDeleteProgressAlertVisibility(true);
                }}
              >
                {t("deleteProgressButtonTitle")}
              </button>
            </div>
          )}
        </div>
      ) : (
        <button
          className="edu-login-button"
          onClick={() => {
            window.location = config.login.url;
          }}
        >
          {t("loginText")}
        </button>
      )}
    </div>
  );
};
export default EduPage;
