import { JsonClient } from "../JsonClient";
import { MvLoginUser } from "./MvLoginUser";
import { Role } from "../role/Role";
import { responseBuilder, ApiResponse } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class MvLoginUserService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}

    public getMvLoginUsers(uuid: string, startIdx: number = 1, endIdx: number = 2): Promise<ApiResponse> {
        return this.client
            .get(`/mvLoginUsers/${uuid}`, startIdx, endIdx)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser[];
                }

                return apiResponse;
            })
        ;
    }

    public getMvLoginUsersByCustomerUUIDClassRole(customerUuid: string, startIdx: number = 1, endIdx: number = 10): Promise<ApiResponse> {
        return this.client
            .get(`/mvLoginUsers/customer/${customerUuid}/retriveRoles/true/retriveClass/true`, startIdx, endIdx)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser[];
                }

                return apiResponse;
            })
        ;
    }

    public getMvLoginUsersByCustomerUUID(customerUuid: string, startIdx: number = 1, endIdx: number = 10): Promise<ApiResponse> {
        return this.client
            .get(`/mvLoginUsers/customer/${customerUuid}`, startIdx, endIdx)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser[];
                }

                return apiResponse;
            })
        ;
    }

    public getMvLoginUsersByTermCustomerUUID(customerUuid: string, term: string, startIdx: number = 1, endIdx: number = 10): Promise<ApiResponse> {
        let b64term = btoa(term);

        return this.client
            .get(`/mvLoginUsers/${b64term}/customer/${customerUuid}`, startIdx, endIdx)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser[];
                }

                return apiResponse;
            })
        ;
    }

    public getMvLoginUsersByTermCustomerUUIDClassRole(customerUuid: string, term: string, startIdx: number = 1, endIdx: number = 10): Promise<ApiResponse> {
        let b64term = btoa(term);

        return this.client
            .get(`/mvLoginUsers/${b64term}/customer/${customerUuid}/retriveRoles/true/retriveClass/true`, startIdx, endIdx)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser[];
                }

                return apiResponse;
            })
        ;
    }

    public getMvLoginUser(uuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/mvLoginUsers/${uuid}`)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser;
                }

                return apiResponse;
            })
        ;
    }

    public getRolesForSessionMvLoginUser(uuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/mvLoginUsers/${uuid}/roles`)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Role[];
                }

                return apiResponse;
            })
        ;
    }

    public getRolesForMvLoginUser(uuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/mvLoginUsers/${uuid}/roles`)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Role[];
                }

                return apiResponse;
            })
        ;
    }

    public addRoleForUser(uuid: string, roleUuid: string, customerUuid: string): Promise<ApiResponse> {
        return this.client
            .put(`/mvLoginUsers/${uuid}/roles/${roleUuid}`, `"${customerUuid}"`) // notice string in string
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Role;
                }

                return apiResponse;
            })
        ;
    }

    // @todo normalize return
    public removeRoleForUser(uuid: string, rolesUserUuid: string): Promise<Boolean> {
        return this.client
            .delete(`/mvLoginUsers/${uuid}/roles/${rolesUserUuid}`)
            .then(r => r.status === 204);
    }

    public newMvLoginUser(mvLoginUser: MvLoginUser): Promise<ApiResponse> {
        return this.client
            .post('/mvLoginUsers', JSON.stringify(mvLoginUser))
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser;
                }

                return apiResponse;
            })
        ;
    }

    public updateMvLoginUser(uuid: string, mvLoginUser: ApiResponse): Promise<ApiResponse> {
        return this.client
            .put(`/mvLoginUsers/${uuid}`, JSON.stringify(mvLoginUser))
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser;
                }

                return apiResponse;
            })
        ;
    }

    public deleteMvLoginUser(uuid: string): Promise<ApiResponse> {
        return this.client
            .delete(`/mvLoginUsers/${uuid}`)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser;
                }

                return apiResponse;
            })
        ;
    }

    public isUsernameUnique(uuid: string, username: string): Promise<ApiResponse> {
        let b64username = btoa(username);
        return this.client
            .get(`/mvloginusers/IsUserNameUnique/${b64username}/${uuid}`)
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as boolean;
                }

                return apiResponse;
            })
        ;
    }

    public updatePassword(userUuid: string, newPassword: string): Promise<ApiResponse> {
        return this.client
            .put(`/mvloginusers/ChangePassword/${userUuid}/`, JSON.stringify(newPassword) )
            .then( r => this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as boolean;
                }

                return apiResponse;
            })
        ;
    }
}