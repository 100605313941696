import { Hmac256Hasher } from "./Hmac256Hasher";
import { responseBuilder, ApiResponse, apiError } from "./ApiResponse";
import { ApiResponseFactory } from "./ApiResponseFactory";

export class JsonClient {

    protected defaultHeaders = {
            'Accept': 'application/json',
            "AuthorizationHashed": "",
            'Content-Type': 'application/json',
            'RequestDateTime': 0,
            'SessionID': ""
    };

    constructor(
        protected baseURL: string,
        protected sessionId: string,
        protected clientId: string,
        protected secret: string,
        protected hasher: Hmac256Hasher,
        protected fetch = window.fetch.bind(window),
    ) {
        this.defaultHeaders.SessionID = sessionId;
    }

    protected setRequestDateTime() {
        this.defaultHeaders.RequestDateTime = Math.floor(Date.now() / 1000);
    }

    protected setAuthorization(path: string, verb: "GET"|"POST"|"PUT"|"DELETE", body = "") {
        let hash = this.hasher.hash(body, verb, this.baseURL + path, this.defaultHeaders.RequestDateTime.toString(), this.secret);
        this.defaultHeaders.AuthorizationHashed = `${this.clientId}:${hash}`;
    }

    public get(path: string, startIdx: number = null, endIdx: number = null, options: RequestInit = null): Promise<Response> {
        let paginationHeaders = { 'Range': `${startIdx}-${endIdx === null ? "" : endIdx}` }
        let optionsHeaders = options && options.headers || {};
        
        this.setRequestDateTime();
        this.setAuthorization(path, "GET");

        if(startIdx !== null && endIdx !== null) {
            return this.fetch(
                `${this.baseURL}${path}`,
                Object.assign({}, { headers: {... this.defaultHeaders, ... paginationHeaders , ... optionsHeaders}, method: "GET"})
            );
        }

        return this.fetch(
            `${this.baseURL}${path}`,
            Object.assign({}, { headers: {... this.defaultHeaders, ... optionsHeaders }, method: "GET"})
        ); 
    }

    public post(path: string, body: string = "", options: RequestInit = null): Promise<Response> {
        let optionsHeaders = options && options.headers || {};

        this.setRequestDateTime();
        this.setAuthorization(path, "POST", body);

        return this.fetch(
            `${this.baseURL}${path}`,
            Object.assign({}, { headers: {... this.defaultHeaders, ... optionsHeaders }, body: body, method: "POST"})
        );
    }

    public put(path: string, body = "", options: RequestInit = null): Promise<Response> {
        let optionsHeaders = options && options.headers || {};

        this.setRequestDateTime();
        this.setAuthorization(path, "PUT", body);

        return this.fetch(
            `${this.baseURL}${path}`,
            Object.assign({}, { headers: {... this.defaultHeaders, ... optionsHeaders}, body: body, method: "PUT"})
        );
    }

    public delete(path: string, body = "", options: RequestInit = null): Promise<Response> {
        let optionsHeaders = options && options.headers || {};

        this.setRequestDateTime();
        this.setAuthorization(path, "DELETE", body);

        return this.fetch(
            `${this.baseURL}${path}`,
            Object.assign({}, { headers: {... this.defaultHeaders, ... optionsHeaders }, body: body, method: "DELETE"})
        );
    }
}