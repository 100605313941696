import { IIdentifiable } from "../IIdentifiable";
import { ITimestamped } from "../ITimestamped";

export class Feature implements IIdentifiable, ITimestamped {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    description: string;
    accessIdentifier: string;
    enabled: boolean;
}