import { JsonClient } from "../JsonClient";
import { FeatureService } from "./FeatureService";
import { Hmac256Hasher } from "../Hmac256Hasher";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class FeatureServiceFactory {
    public make(baseURL: string, sessionId: string, clientId: string, secret: string) {
        const client = new JsonClient(baseURL, sessionId, clientId, secret, new Hmac256Hasher);
        const apiResponseFactory = new ApiResponseFactory();

        return new FeatureService(client, apiResponseFactory);
    }
}
