export class CreateUserRequest { 
    Username: string;
    Password: string;
    SurName: string;
    GivenName: string;
    Address: string;
    City: string;
    PostCode: string;
    CountryRegionCode: string;
    ExpiryTime: Date;
    Consent: boolean;
    Newsletter: boolean;
    Phonenumber: string;
}