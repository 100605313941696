import {
    signonService,
    subscriptionService,
    userManagementService
  } from "../api/service";

import { config } from "../config";


export const serviceConfigurator = (sessionId, secret = "") => {

    signonService.configure(
    config.api.signon.url,
    sessionId,
    config.api.clientId,
    secret
    );

    [
    subscriptionService,
    userManagementService
    ].map( service => service.configure(
        config.api.mvidapi.url,
        sessionId,
        config.api.clientId,
        secret
    ));
};