import React from "react";
// Logos
import chromeLogo from "../img/chromeLogo.png";
import windowsLogo from "../img/windowsLogo.png";
import iOSLogo from "../img/appleLogo.png";
import macLogo from "../img/macLogo.png";
import androidLogo from "../img/androidLogo.png";

const PlatformPickerButton = ({
  platform,
  allPlatforms,
  isDisabled,
  className,
  onClick
}) => {
  return (
    <button
      key={platform}
      className={className}
      disabled={
        allPlatforms.indexOf(platform.toLowerCase()) === -1 || isDisabled
      }
      onClick={() => {
        onClick(platform);
      }}
    >
      {platform.toLowerCase().includes("extension") ? (
        <img src={chromeLogo} alt={""} />
      ) : platform.toLowerCase().includes("windows") ? (
        <img src={windowsLogo} alt={""} />
      ) : platform.toLowerCase().includes("mac") ? (
        <img src={macLogo} alt={""} />
      ) : platform.toLowerCase().includes("android") ? (
        <img src={androidLogo} alt={""} />
      ) : platform.toLowerCase().includes("ios") ? (
        <img src={iOSLogo} alt={""} />
      ) : null}
    </button>
  );
};

export default PlatformPickerButton;
