import { JsonClient } from "../JsonClient";
import { Customer } from "./Customer";
import { MvLoginUser } from "../mvLoginUser/MvLoginUser";
import { SchoolClass } from "../index";
import { responseBuilder, ApiResponse } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";


export class CustomerService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}

    public search(term: string): Promise<ApiResponse> {
        let b64term = btoa(term);

        return this.client
            .get(`/customers/search/${b64term}`, 1, 10)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer[];
                }

                return apiResponse;
            })
        ;
    }

    public getCustomers(term: string = null, parentUuid: string = null, startIdx: number = 1, endIdx: number = 2): Promise<ApiResponse> {
        let extraArgs = "/";
        if (term !== null) {
            extraArgs = "/search/" + btoa(term);
        }   
        if (parentUuid !== null) {
            extraArgs = "/" + encodeURIComponent(parentUuid) + "/customers";
        }

        return this.client
            .get(`/customers${extraArgs}`, startIdx, endIdx)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer[];
                }

                return apiResponse;
            })
        ;
    }

    public getCustomer(uuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/customers/${uuid}`)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer[];
                }

                return apiResponse;
            })
        ;
    }

    public getMvLoginUsers(uuid: string): Promise<ApiResponse> {
        return this.client
            .get(`/users/*/customer/${uuid}`)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as MvLoginUser[];
                }

                return apiResponse;
            })
        ;
    }

    public newCustomer(customer: Customer): Promise<ApiResponse> {
        return this.client
            .post('/customers', JSON.stringify(customer))
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer;
                }

                return apiResponse;
            })
        ;
    }

    public updateCustomer(uuid: string, customer: Customer): Promise<ApiResponse> {
        return this.client
            .put(`/customers/${uuid}`, JSON.stringify(customer))
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer;
                }

                return apiResponse;
            })
        ;
    }

    public addSubCustomers(customerUuid: string, customersUuid: string[]): Promise<ApiResponse> {
        return this.client
            .put(`/customers/${customerUuid}/customers`, JSON.stringify(customersUuid))
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer;
                }

                return apiResponse;
            })
        ;
    }

    public deleteSubCustomers(customerUuid: string, customersUuid: string[]): Promise<ApiResponse> {
        return this.client
            .delete(`/customers/${customerUuid}/customers`, JSON.stringify(customersUuid))
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Customer;
                }

                return apiResponse;
            })
        ;
    }
}