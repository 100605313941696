import { JsonClient } from "../JsonClient";
import { Feature } from "./Feature";
import { ApiResponse, responseBuilder } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class FeatureService {

    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}
    
    public getFeatures(startIdx = 1, endIdx = 2): Promise<ApiResponse> {
        return this.client
            .get('/features', startIdx, endIdx)
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Feature[];
                }

                return apiResponse;
            })
        ;
    }
}
