export type apiError = {error: string, exceptionId: number};

export class ApiResponse {
    public header: {};
    public data: any;
    public status: number = 200;
    public error: apiError = {error: null, exceptionId: null};
    /**
     * is status between inclusive 200-299
     */
    public ok: boolean 
}

export function responseBuilder(status: number, headers: any, data: any): ApiResponse {
    let response = new ApiResponse();

    response.status = status;
    response.header = headers || {};

    if( Math.floor( status / 100 ) !== 2) {
        response.error = data as apiError;
        throw response;
    } else {
        response.data = data;
    }

    return response;
}