import { JsonClient } from "../JsonClient";
import { Permission } from "./Permission";
import { ApiResponse, responseBuilder } from "../ApiResponse";
import { ApiResponseFactory } from "../ApiResponseFactory";

export class PermissionService {
    
    constructor(
        protected client: JsonClient,
        protected apiResponseFactory: ApiResponseFactory
    ){}

    public getPermissions(): Promise<ApiResponse> {
        return this.client.get('/permissions')
            .then( r =>  this.apiResponseFactory.from(r))
            .then( apiResponse => {
                if(apiResponse.ok) {
                    apiResponse.data = apiResponse.data as Permission[];
                }

                return apiResponse;
            })
        ;
    }
}